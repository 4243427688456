import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { StaticPagesTemplate } from '../../components/common/static-pages-template';

function WestminsterDivorceAttorney({ data }) {
  return (
    <StaticPagesTemplate data={data} />
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/westminster-divorce-attorney/"}) {
      content
      title
      uri
      ...SeoPage
    }
  }
`;

WestminsterDivorceAttorney.propTypes = {
  data: object,
};

WestminsterDivorceAttorney.defaultProps = {
  data: {},
};

export default WestminsterDivorceAttorney;
export { Head } from '../../components/seo/seo';
